import React, {useEffect} from "react"
import { navigate } from 'gatsby';
import Spinner from '../components/templates/Spinner';

const NotFoundPage = () => {
  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token === "" || token === null || token === undefined) {
      navigate("/")
    }else{
      navigate("/inicio/")
    }
  }, [])
  return(
    <>
      <Spinner/>
    </>
  )
}
export default NotFoundPage
